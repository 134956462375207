html {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
* {
    box-sizing: inherit;
    font-family: 'Roboto', sans-serif;
    color: #555;
}
a,
input {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    text-decoration: none;
    appearance: none;
}
body {
    background: #eeeeee;
}
h1,
h2 {
    padding: 0;
    margin: 0;
    font-weight: 500;
}
